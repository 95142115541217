import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import styles from "./CustomSkeleton.module.css"

interface SkeletonBoneProps {
  style: React.CSSProperties
}
const SkeletonBone: React.FC<SkeletonBoneProps> = ({ style }) => {
  const [position, setPosition] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition((prevPosition) => (prevPosition + 10) % 200)
    }, 100)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div
      className={styles.skeletonBone}
      style={{
        ...style,
      }}
    >
      <div
        className={styles.animationBar}
        style={{
          left: `${position - 200}%`,
        }}
      ></div>
    </div>
  )
}

const CustomSkeleton = () => (
  <Container fluid>
    <Row>
      <Col className={styles.customSkeleton}>
        <SkeletonBone style={{ backgroundColor: "#f0f0f0" }} />
        <SkeletonBone
          style={{
            backgroundColor: "var(--fiva-secondary-color)",
          }}
        />
        <SkeletonBone style={{ backgroundColor: "#f0f0f0" }} />
      </Col>
    </Row>
  </Container>
)

export default CustomSkeleton
